<template>
        <div class="md:p-6" >
      <Buy1 /> 
  </div>
</template>

<script>
import Buy1 from "@/components/core/Buy1.vue"; 

export default {
    components:{
        Buy1
    }
}
</script>

<style>

</style>